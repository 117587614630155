import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery';


import "antd/dist/antd.css";

import { Form, Input, Button, Alert } from "antd"
import { UserOutlined, LockOutlined, LoginOutlined } from "@ant-design/icons"


//import AllStreamIcon from "../assets/AllStreamIcon";
import SpeadivCompanyAndLogoiCON from "../assets/SpeadivCompanyAndLogo.js";

// onFinish
// onFinishFailed
const LoginForm = ({OnFinishCB, isRungUserOrPassword}) => {
  const isTinyScreen = useMediaQuery('(max-width:300px)');
  const isTinyScreenHeight_350 = useMediaQuery('(max-height:350px)');
  const isTinyScreenHeight = useMediaQuery('(max-height:801px)');
  const isMedumeScreen = useMediaQuery('(max-width:400px)');
  const onFinish = values => {
    OnFinishCB(values["username"], values["password"]);
  }


  const onFinishFailed = errorInfo => {}

  const wrongUserMsg = <Alert message="There is a problem with the user or password, please try again" type="error" style={{ width: "300px", margin: "60px auto" }}/>;

  var ourWidth = "300px";
  if (isTinyScreen === true || isTinyScreenHeight === true)
  {
    ourWidth = "200px";
  } else if (isMedumeScreen === true)
  {
    ourWidth = "220px";
  }

  var innerStyle = {
    display: "flex",
    alignItems: "flex-start", //"center",
    justifyContent: "center",
    height: "95vh", 
    overflowY: isTinyScreenHeight ? "scroll" : null,
  };

  return (
<div
  style={{
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    minHeight: "100vh",
    backgroundColor: "#ECEBEB",
    overflowY: isTinyScreenHeight ? "scroll" : "auto",
  }}
>
<div // Add this wrapping div
        style={innerStyle}
      >

  <Form
    style={{ width: ourWidth, margin: isTinyScreenHeight_350 ? "5px auto" : "60px auto" }}
    name="basic"
    labelCol={{
      span: 8,
    }}
    wrapperCol={{
      //span: 16,
      span:  20 
    }}
    initialValues={{
      remember: true,
    }}
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    autoComplete="off"
  >
    <Form.Item 
    style={{ margin: "50px auto"}} >
      { <SpeadivCompanyAndLogoiCON style={{ margin: "50px auto" }} />}
    </Form.Item>
    <Form.Item
      wrapperCol={{
        span: 20,
      }}
      name="username"
      rules={[
        {
          required: true,
          message: "Please input your username!",
        },
      ]}
    >
      <Input prefix={<UserOutlined />} placeholder="Username" />
    </Form.Item>

    <Form.Item
      wrapperCol={{
        span: 20,
      }}
      name="password"
      rules={[
        {
          required: true,
          message: "Please input your password!",
        },
      ]}
    >
      <Input.Password prefix={<LockOutlined />} placeholder="Passwor" />
    </Form.Item>

    <Form.Item
      wrapperCol={{
        span: 20,
      }}
    >
      <Button
        type="primary"
        htmlType="submit"
        icon={<LoginOutlined />}
        block
      >
        Login
      </Button>
    </Form.Item>
  </Form>

  {isRungUserOrPassword === true ? wrongUserMsg : null}
  </div>
</div>
)
}


/* move to styled ... */
export default LoginForm;