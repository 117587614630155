import React, { useState } from 'react'
import { navigate } from "gatsby"
import { useSelector, useDispatch } from 'react-redux'

import { Helmet } from 'react-helmet';

import LoginForm from "../components/loginForm";
import { SetToken } from "../store/store";

import axios from "../projectAxios";


const UNAUTHORIZED_CODE = 401;

const Login = () => {
  const dispatch = useDispatch()
  const [isRungUserOrPassword, setIsRungUserOrPassword] = useState(false);
  const isLoggedIn = useSelector((state) => state !== undefined ? state.isLoggedIn : false);

  // if we loggedIn -> redirect to home
  if (isLoggedIn === true)
  {
    const moveUsingRefresh = window.location.origin + "/dashboard";

    const isBrowser = typeof window !== "undefined";
    if (isBrowser === true)
    {
      window.location.replace(moveUsingRefresh);
    }
    else
    {
      navigate(moveUsingRefresh);
    }
    
    return null
  }

  const loginBackEnd = (username, password) => 
  {
      // here is the exius req.
      axios.post("/Auth/Login", {}, { headers: {username, password} })
      .then(resp => {

        setIsRungUserOrPassword(false);
        dispatch(SetToken(resp.data));
      })
      .catch(err => {
        if (err.response && err.response.status === UNAUTHORIZED_CODE) {          
          // Bad user/password
          setIsRungUserOrPassword(true);
        }
      })
  }


  return (
      <>
        <Helmet>
            <title>Login</title>
        </Helmet>
        
        <div>
            <LoginForm OnFinishCB={loginBackEnd} isRungUserOrPassword={isRungUserOrPassword}/>
        </div>
      </>)
}

export default Login;